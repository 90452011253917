<template>
  <div>
    <v-footer dark color="#F0F3F4" class="pa-0">
      <v-container class="pt-6">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="6">
            <img src="@/assets/images/logo.svg" width="200" alt="" />
            <p class="mt-3 mb-1 footer-text font-weight-bold">
              {{ $t("公司电话") }}
            </p>
            <p class="mb-0 footer-text">15038350530</p>
            <p class="mt-3 mb-1 footer-text font-weight-bold">
              {{ $t("公司邮箱") }}
            </p>
            <p class="mb-0 footer-text">97668216@qq.com</p>
            <p class="mt-3 mb-1 footer-text font-weight-bold">
              {{ $t("公司地址") }}
            </p>
            <p class="mb-1 footer-text">
              {{ $t("河南省郑州市金水区花园路正弘中心1417") }}
            </p>
            <!-- <div class="d-flex flex-sm-nowrap flex-wrap">
                    <a href="#" class="me-3 mb-3">
                        <v-img max-width="130" src="@/assets/images/app-store/google-play.png"></v-img>
                    </a>
                    <a href="#">
                        <v-img max-width="130" src="@/assets/images/app-store/app-store.png"></v-img>
                    </a>
                </div> -->
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <p class="mb-2 text--primary text-body-1 weight500">
              {{ $t("联系我们") }}
            </p>
            <p class="mt-0 mb-1 footer-text font-weight-bold">
              {{ $t("微信号") }}： zzqss001
            </p>
            <p class="mb-2 text--primary text-body-1 weight500 pt-6">
              {{ $t("加入我们") }}
            </p>
            <v-row class="ma-0">
              <a href="https://www.facebook.com/alibabaagent" target="_blank">
                <v-btn
                  class="me-4 white--text"
                  elevation="0"
                  width="40"
                  height="40"
                  fab
                  dark
                  color="#fff"
                >
                  <v-img src="@/assets/images/home/kefu1.png" width="40">
                  </v-img>
                </v-btn>
              </a>
              <v-menu offset-y top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="me-4 white--text"
                    elevation="0"
                    width="40"
                    height="40"
                    fab
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="#fff"
                  >
                    <v-img src="@/assets/images/home/kefu2.png" width="40">
                    </v-img>
                  </v-btn>
                </template>
                <v-card>
                  <v-img
                    src="@/assets/images/recruit/erweima.png"
                    width="200"
                    height="200"
                  ></v-img>
                </v-card>
              </v-menu>
              <a href="https://twitter.com/mkMfnDdfNeAuPso" target="_blank">
                <v-btn
                  class="me-4 white--text"
                  elevation="0"
                  width="40"
                  height="40"
                  fab
                  dark
                  color="#fff"
                >
                  <v-img src="@/assets/images/home/kefu3.png" width="40">
                  </v-img>
                </v-btn>
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-card class="" flat color="#E6E6E6">
      <v-container style="" class="font18">
        <v-row class="ma-0" align="center">
          <router-link to="/about-us" class="color80">
            <span>{{ $t("关于我们") }}</span>
          </router-link>
          <v-card width="1" height="16" class="mx-3" color="#808080"></v-card>
          <router-link to="/privacy-policy" class="color80">
            <span>{{ $t("隐私政策") }}</span>
          </router-link>
          <v-card width="1" height="16" class="mx-3" color="#808080"></v-card>
          <router-link to="/question" class="color80">
            <span>{{ $t("帮助中心") }}</span>
          </router-link>
        </v-row>
        <p class="mb-0 color80 text-caption mt-1">
          Copyright ©2022 zzqss (豫) | 郑州全搜索计算机技术服务有限公司
        </p>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    icons: [
      "mdi-facebook",
      "mdi-twitter",
      "mdi-youtube",
      "mdi-google",
      "mdi-instagram",
    ],
    aboutUs: [
      "Careers",
      "Our Stores",
      "Our Cares",
      "Terms & Conditions",
      "Privacy Policy",
    ],
    customerCare: [
      "Help Center",
      "How to Buy",
      "Track Your Order",
      "Corporate & Bulk Purchasing",
      "Returns & Funds",
    ],
  }),
};
</script>
<style lang="scss">
.footer-text {
  color: #808080 !important;
  &:hover {
    color: #333 !important;
  }
}
a.footer-link-hover {
  color: #808080 !important;
  &:hover {
    color: #333 !important;
  }
}
.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.color80 {
  color: #808080 !important;
}
</style>